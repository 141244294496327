import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const ZapisPodkastov = () => {
  return (
    <>
      <Helmet>
        <title>Запись подкастов в Москве цена от студии Elandic</title>
        <meta name="description" content="Запись подкастов в Москве цена услуг на сайте видеопродакшена Elandic. Заказать профессиональную съемку видеоподкастов в студии стоимость онлайн." />
        <meta name="keywords" content="запись подкастов, студия, москва, съемка" />
      </Helmet>
      <MainPage>
        <SeoText title='Запись подкастов'>
          <p>Время подкастов пришло, и их аудитория продолжает набирать обороты с каждым днем, превращаясь из простого хобби в мощный инструмент маркетинга и важный элемент культуры. Видеопродакшн Elandic предлагает вам свои уникальные услуги по записи подкастов в Москве, обеспечивая высокое качество звука, профессиональный продакшн и доступные цены, делая процесс создания подкастов простым и приятным.</p>

          <p>Студия Elandic оснащена современным оборудованием для записи подкастов и видеороликов, что позволяет достигать кристально чистого звучания и высокого качества изображения. Наша команда состоит из опытных звукоинженеров, видеооператоров и редакторов, которые помогут вам на каждом этапе – от первоначальной концепции до финальной версии вашего подкаста.</p>

          <p>Мы предлагаем гибкую ценовую политику, чтобы запись подкастов была доступна для всех – от индивидуальных блоггеров до крупных корпораций. В услуги входит не только сама запись, но и предварительная подготовка, обработка звука, сведение и мастеринг. </p>

          <p>Запись подкастов в Москве от студии Elandic – это отличная возможность воплотить ваши идеи в жизнь и достучаться до сердец вашей аудитории. Воспользовавшись нашими услугами, вы получите не только качественный продукт, но и поддержку на каждом этапе создания вашего подкаста. В студии Elandic мы ценим каждого клиента и стремимся обеспечить индивидуальный подход к выполнению каждого проекта. Позвольте вашему голосу зазвучать по-новому вместе с Elandic!</p>
        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(ZapisPodkastov);
